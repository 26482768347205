.container {
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #0A0C19;
  color: var(--l-white);
  .header {
    z-index: 100;
    width: 100%;
    position: sticky;
    top: 0;
  }
  .page {
    z-index: 1;
    width: 100%;
    flex: 1;
  }
  .footer {
    // z-index: 1;
    width: 100%;
  }
  .cs {
    position: fixed;
    top: 85%;
    left: 92%;
    border-radius: 50%;
    cursor: pointer;
    z-index: 999;
  }
}

.loading {
  height: 100vh;
  height: 100dvh;
  width: 100vw;
  width: 100dvw;
  position: fixed;
  z-index: 8888;
  top: 0;
  left: 0;
  right: 0;
  background-color: black;
}
