.marqueeCon {
  display: flex;
  .marquee{
    display: flex;
    overflow: hidden;
    width: 100vw;
    .rotateMe {
      display: flex;
      animation: marquee 80s linear infinite;
      animation-delay: 1s;
      > span {
        min-width: 10vw;
        margin: 0 10px !important;
      }
      // img{
      //   cursor: pointer;
      //   &:hover {
      //     opacity: 0.7;
      //   }
      // }
      &:hover {
        animation-play-state: paused;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .marqueeCon {
    .marquee{
      .rotateMe {
        > span {
          min-width: 20vw;
          margin: 0 10px !important;
        }
      }
    }
  }
}


@keyframes marquee {
  0% {
    transform: translate(0%, 0);
  }

  100% {
    transform: translate(-50%, 0);
  }
}