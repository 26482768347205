@import "../../../../styles/var.module.scss";

.container {
  position: relative;
  padding: rem(100px);
  overflow: hidden;
  max-width: rem(1000px);
  background-color: #181A22;
  border-radius: rem(30px);
  color: $mainFont1;
  text-align: center;
  font-size: rem(28px);

  .cross {
    position: absolute;
    right: 5%;
    top: 5%;
    cursor: pointer;
  }

  > div {
    margin-bottom: rem(90px);
  }

  .title {
    font-size: rem(36px);
    font-weight: 500;
  }

  .content {
    font-size: 0.875rem;
    line-height: 1.2rem;
  }

  .btn {
    padding: rem(44px);
    color: black;
    background: $icCtaBlue;
    border-radius: rem(100px);
    font-weight: 600;
    margin-bottom: rem(40px);
    cursor: pointer;
    word-wrap: break-word;

    &.btnFade {
      color: $sgGrey1;
      background: #3C3D40;
      // border: 1px solid $sgGrey1;
      font-weight: 500;
    }
  }

}