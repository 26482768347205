@import "@/styles/var.module.scss";

.container {
  // background-color: #181a20;
  font-size: 0.875rem;
  font-family: IBM_Plex_Sans;
  .content {
    max-width: calc(1200px + 2rem);
    margin: auto;
    padding: 1rem 2rem;
    line-height: 1.5;
    color: #7C7E87;
    .logosContent {
      margin-bottom: 3rem;
      .logos {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
    .divider {
      border: none;
      border-top: 1px solid #2d313a;
      margin: 1rem 0;
    }
    .mobile {
      display: none;
    }
    .companyInfo {
      display: flex;
      justify-content: space-between;
      width: 100%;

      .linkButton {
        cursor: pointer;
        display: block;
      }
      .title {
        color: var(--l-white);
        font-family: IBM_Plex_Sans;
        font-weight: 500;
        font-size: 16px;
      }
      .aboutUs {
        width: 15%;
      }
      .products {
        width: 15%;
      }
      .service {
        width: 15%;
      }
      .support {
        width: 20%;
      }
      .learn {
        width: 20%;
      }
      .community {
        width: 15%;
      }
    }
    .socialButtons {
      display: flex;
      gap: 1.5rem;
      flex-wrap: wrap;
    }
    .copyright {
      text-align: center;
    }
  }
}

@media screen and (max-width: 768px) {
  .container {
    .content {
      .logosContent {
        .logos {
          gap: 30px;
        }
      }
      .mobile {
        display: flex;
        .routesCon {
          width: 100%;
          color: #E7E9EC;
        }
        .route {
          cursor: pointer;
          padding: 18.5px 0;
          display: flex;
          align-items: center;
          svg {
            margin-right: 20px;
          }
        }
        .subroute {
          padding: 13px 10px;
          cursor: pointer;
        }
        details{
          summary{
          }
          
          summary::-webkit-details-marker,
          summary::marker{
            content: "";
            display: none;
          }
          
          summary::after{
            content: url("../../../src/layout/header/icons/arrow_down.svg");
            margin-left: auto;
            margin-top: 5px;
            svg {
              color: $sgGrey1;
              // width: 10px !important;
            }
          }
        }
    
        details[open]{
          summary::after{
            content: url("../../../src/layout/header/icons/arrow_up.svg");
          }
        }
        .community {
          width: 100%;
          .socialButtons {
            color: #7C7E87;
          }
        }
        
      }
      .desktop {
        display: none;
      }
    }
  }
}
